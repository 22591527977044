import React, {useEffect, useState} from 'react'
import { 
    Grid, Zoom, Button, Backdrop, CircularProgress,
    Snackbar, Paper, Typography
} from '@material-ui/core'
import questConfig from '../../config/questConfig.json'
import { handlePayload } from '../../utils'
import CategoryButton from './CategoryButton'
import axios from 'axios'
import constants from '../../constants'

/*
Props
categories
setScreen
user
extended - deprecated
isLogged
*/
const Category = (props) => {
    let prog = props.user.prog
    const [cats, setCats] = useState([])
    const [zoom, setZoom] = useState(false)
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)

    useEffect(() => {
        let mounted = true

        axios.get('/user')
        .then((res)=>{
            console.log(res)
            if(res.status === 200){
                if(mounted){
                    setZoom(true)
                }
            }
            else{
                if(mounted){
                    setZoom(false)
                }
            }
        })
        .catch((err)=>{
            if(mounted){
                setZoom(false)
            }
        })

        return () => {
            setSnackOpen(false)
            mounted = false
        }
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        setCats(sortCategories(props.categories))
    },[props.categories])
    
    function handleSave(){
        setShowBackdrop(true)
        setZoom(false)
        axios.get('/user')
        .then((res)=>{
            //console.log(res)
            if(res.status === 200){
                let options = {
                    email: res.data.email,
                    update: props.user
                }
                axios.patch('/user', options)
                .then((res)=>{
                    setShowBackdrop(false)
                    setSnackOpen(true)
                    setZoom(true)
                    //console.log(res)
                })
                .catch((err)=>{
                    setShowBackdrop(false)
                    setZoom(true)
                    //console.log(err)
                })
            }
            else{
                props.setScreen('LoginScreen.CategoryScreen.disabled')
            }
        })
        .catch((err)=>{
            setZoom(false)
        })
    }

    function handleExit(){
        axios.get('/user/logout')
        .then(()=>{
            props.setUserCb({prog:{}, avatar: ""},()=>{
                props.setScreen("FrontScreen")
            })
        })
        .catch(()=>{
            props.setUserCb({prog:{}, avatar: ""},()=>{
                props.setScreen("FrontScreen")
            })
        })
    }
    //generate map
    let map = []

    if(cats){
        map.push(cats.map((item, index)=>{
            let catprog = prog[item]
            let disabled = true;
            if(index === 0){
                disabled = false
            }
            if(index > 0 && prog[questConfig.order[0]] !== undefined){
                disabled = false
            }
            return <Grid item xs={12} key={'cat-'+index} style={
                {
                    width:'100%'
                }}>
                <CategoryButton disabled={disabled} handleClick={()=>{props.setScreen(handlePayload( catprog ? "ResultsScreen" : "IntroScreen",item,index+1,cats, catprog ? 'no':'yes'))}} item={item} index={index} prog={catprog}/>
                    {/* <Button onClick={()=>{props.setScreen(handlePayload("IntroScreen",item,index+1,cats))}}>
                            <Grid container direction='column'>
                                <Grid item>
                                    {item}
                                </Grid>
                                <Grid item>
                                    <img src="/logo512.png" alt="mountain" style={{width:'100%', maxWidth:'50px', height:'auto'}}></img>
                                </Grid>
                            </Grid>
                        </Button> */}
            </Grid>
        }))
    }
    return (
        <>
        <Backdrop open={showBackdrop} style={{zIndex:'50'}}>
            <CircularProgress/>
        </Backdrop>
        <Snackbar open={snackOpen} 
            autoHideDuration={5000} 
            onClose={()=>{setSnackOpen(false)}}
            anchorOrigin={{vertical:'top',horizontal:'center'}}
        >
            <Paper style={{backgroundColor:constants.colors.map, padding:'20px'}} onClick={()=>{setSnackOpen(false)}}>
                <Typography variant="body1">
                    Tiedot tallennettu!
                </Typography>
            </Paper>
        </Snackbar>
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={3}
            style={{
                backgroundImage:'url(/images/Yrityspeli_kuvitukset_2.0_Vuori.png)',
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                backgroundPosition:'center',
            }}
        >
            <Zoom in={zoom}>
                <Grid item xs={12}>
                    <Button onClick={()=>{handleSave()}} variant="contained" color="primary">Tallenna tulokset</Button>
                </Grid>
            </Zoom>
            <Grid item>
                <Grid container
                    direction="column-reverse"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    {map ? map : null}
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={()=>{handleExit()}} variant="contained" color="primary">{zoom ? "Kirjaudu ulos" : "Lopeta peli"}</Button>
            </Grid>
        </Grid>
        </>
    )
}

//Return string of category names. Also sort them according to questOrder
function sortCategories(catNames){
    let cats = []

    //order cats
    for(let i in questConfig.order){
        for(let j in catNames){
            if(catNames[j] === questConfig.order[i]){
                cats.push(catNames[j])
            }
        }
    }
    return cats
}

export default Category