import React from 'react'
import {Button,Dialog,DialogTitle,DialogContent,Grid,DialogActions,Typography} from '@material-ui/core'

function Credits({ open, onClose }) {

    
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container>
                        <Grid item>
                            <Typography variant="h4">Tekijät</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">Sisältö ja visuaalinen ilme</Typography>
                                <Typography variant="body1">Miro Eskelinen</Typography>
                                <Typography variant="body1">Tiina Hirvanen</Typography>
                                <Typography variant="body1">Janika Kemppainen</Typography>
                                <Typography variant="body1">Anni Nyberg</Typography>
                                <Typography variant="body1">Nita Söyrilä</Typography>

                                <Typography variant="h5">Tekninen toteutus</Typography>
                                <Typography variant="body1">Simo Wesa</Typography>
                                <Typography variant="body1">Kalle Louhimies</Typography>

                                <Typography variant="h5">LAB-ammattikorkeakoulu</Typography>
                                <Typography variant="body1">Anna Palokangas</Typography>
                                <Typography variant="body1">Olli Koskimaa</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" variant="contained">
                        Sulje
                    </Button>
                </DialogActions>
            </Dialog>     
        </div>
    )
}

export default Credits