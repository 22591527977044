import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { CircularProgress, Grid} from '@material-ui/core'

import Category from '../components/game/Category'
/*
Props
setScreen()
setUserCb()
content - deprecated
isLogged()
*/
const CategoryScreen = (props) =>{
    const [categories, setCategories] = useState(null)
    useEffect(() => {
        let mounted = true

        axios.get('questions/categories')
        .then((res)=>{
            //console.log(res)
            if(mounted){
                setCategories(res.data)
            }
        })
        .catch(err=>{
            //console.log(err)
            if(mounted){
                props.setScreen("NoConnection")
            }
        })
        return ()=>{
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let jsxContent;
    switch(props.content){
        case "Category":
            jsxContent = categories == null ? <CircularProgress/> : <Category categories={categories} setScreen={(screen)=>{props.setScreen(screen)}} setUserCb={props.setUserCb} isLogged={props.isLogged} user={props.user}/>
            break;
        case "CategoryExtended":
            jsxContent = categories == null ? <CircularProgress/> : <Category categories={categories} setScreen={(screen)=>{props.setScreen(screen)}} setUserCb={props.setUserCb} isLogged={props.isLogged} user={props.user} extended/>
            break;
        default:
            jsxContent = <CircularProgress/>
            break;
    }
    return(
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={3}
            style={{
                marginBottom:'50px',
            }}
        >
            <Grid item xs={12} style={{width:'100%'}}>
                {jsxContent}
            </Grid>
        </Grid>
    )
}

export default CategoryScreen