//Cookie consent Banner with Popover
import React, { useState } from 'react'
import { Button, Popover, Paper, Typography } from '@material-ui/core'
import constants from '../../constants'


const CookieBanner = (props) => {

    //For checking if Banner or Popover should be shown
    const [showBanner,setShowBanner] = useState(true)
    const [showPop, setShowPop] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const clickHandler = (e) => {
        setShowPop((open) => !open);
        setAnchorEl(e.target);
      };
    
    if (showBanner === false || props.isLogged() === true) {
        sessionStorage.setItem('bannerShown', 'true');
    }
        
    return (
        
        <div className="CookieBanner">

            {showBanner && (!sessionStorage.getItem('bannerShown'))
                ?
                <div id="cookie-container" style={{
                    position: 'fixed', bottom:0, left:0, width:'100%',height:'10%', paddingTop:'10px',
                    backgroundColor:constants.colors.primary.light
                }}>
                    {/* eslint-disable-next-line */}
                    Pelaamalla hyväksyt <button style={{'fontSize':15,background:'none',border:'none',padding:0,color:'darkblue',textdecoration:'underline',cursor:'pointer'}} onClick={()=>setShowPop(true), clickHandler}>evästekäytännöt</button>. <Button id="cookie-btn" variant="contained" color="primary" onClick={()=>setShowBanner(false)}>Sulje</Button> 
                </div>
                :
                null
            }
            {showPop
                ?
                <div>
                    <Popover 
                    anchorEl={anchorEl}
                    open={showPop}
                    onClose={() => setShowPop(false)}
                    anchorOrigin={{vertical: "top",horizontal: "center"}}
                    transformOrigin={{vertical: "bottom",horizontal: "center"}}>
                        <Paper style={{padding:'10px', backgroundColor:constants.colors.map}}>
                            <Typography variant="body1">
                                Tämä sivusto käyttää evästeitä. <br/> 
                                Eväste poistetaan automaattisesti 24 tunnin kuluttua luomisesta.<br/> 
                                Evästeitä käytetään sovelluksen aktiivisuuden tarkkailuun. (Henkilökohtaisia tietoja ei tallenneta) 
                            </Typography>
                        </Paper>
                    </Popover>
                </div>
                :
                null
            }
        </div>

    )
    
}

export default CookieBanner