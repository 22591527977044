import React, {useState} from 'react'
import { Grid, Typography, Dialog, DialogContent, Paper } from '@material-ui/core'
import questConfig from '../../config/questConfig.json'
import { isEmpty } from '../../utils'
import { PersonaPanel, PersonaDetailsPanel, DetailsPanel } from '../../Screens/ResultScreen'
import constants from '../../constants'
/*
Props
profile
*/
const Profile = (props) => {
    const [personaPanel, setPersonaPanel] = useState(false)
    const [personaDetails, setPersonaDetails] = useState(false)
    const [details, setDetails] = useState(false)
    const [dContent, setDContent] = useState({})
    const [dSubtitle, setDSubtitle] = useState("")

    let prof = props.profile
    let persona = questConfig.PersonaDetails[prof.avatar]
    let personaImg = ""

    if(persona){
        personaImg = persona.imgsrc
    }
    let prog = prof.prog

    function handlePanel(val){
        val = val.split('.')
        //console.log(val)
        if(val[0] === "gp"){
            setPersonaPanel(true)
        }
        if(val[0] === "PersonaDetails"){
            setPersonaPanel(false)
            setPersonaDetails(true)
        }
        if(val[0] === "Points"){
            setPersonaPanel(false)
            setDetails(false)
        }
        if(val[0] === "Persona"){
            setPersonaPanel(true)
            setPersonaDetails(false)
        }
        if(val[0] === "closeAll"){
            setPersonaPanel(false)
            setPersonaDetails(false)
            setDetails(false)
        }
        if(val[0] === "details"){
            let config = questConfig.categories[val[1]]
            let sub = config.linkSubtitle
            let links = config.links
            setDSubtitle(sub)
            setDContent(links)
            setDetails(true)
        }
    }
    return (
        <>
        <Grid container
            direction="row"
            justify="center"
            alignContent="center"
            spacing={2}
        >
            {personaImg === "" ? null : 
            <Grid item xs={4} onClick={()=>{handlePanel("gp")}}>
                <Paper style={{backgroundColor:constants.colors.map}}>
                <img alt="persona" src={personaImg} style={{
                    maxHeight:'100%',
                    maxWidth:'100%'
                }}/>
                <Typography variant="h6">
                    {persona.title}
                </Typography>
                </Paper>
            </Grid>
            }
            <Grid item xs={6}>
                {isEmpty(prog) ? null : 
                <Grid container
                    direction="column"
                    justify="center"
                    alignContent="center"
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant="h6">
                            Pelattuja kategorioita:
                        </Typography>
                    </Grid>
                    {Object.keys(prog).map((item)=>{
                        return(
                            <Grid item key={item} onClick={()=>{handlePanel('details.'+item)}}>
                                <Paper style={{backgroundColor:constants.colors.map, padding:'5px'}}>
                                    <Typography variant="body1">
                                        {item}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
                }
            </Grid>
            
        </Grid>
        <Dialog open={personaPanel} onClose={()=>{handlePanel("closeAll")}}>
            <DialogContent style={{backgroundColor:constants.colors.map}}>
                <PersonaPanel dialog={true} p={prof.avatar} setPanel={(val)=>{handlePanel(val)}}/>
            </DialogContent>
        </Dialog>
        <Dialog open={personaDetails} onClose={()=>{handlePanel("closeAll")}}>
            <DialogContent style={{backgroundColor:constants.colors.map}}>
                <PersonaDetailsPanel dialog={true} persona={prof.avatar} setPanel={(val)=>{handlePanel(val)}}/>
            </DialogContent>
        </Dialog>
        <Dialog open={details} onClose={()=>{handlePanel("closeAll")}}>
            <DialogContent style={{backgroundColor:constants.colors.map}}>
                <DetailsPanel links={dContent} subtitle={dSubtitle} setPanel={(val)=>{handlePanel(val)}}/>
            </DialogContent>
        </Dialog>
        </>
    )
}


export default Profile