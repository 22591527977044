import React, { useState } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import axios from 'axios'

/* 
Props
handleUser -- deprecated
setUserCb
setScreen
screen
onClick
*/
const Login = (props) => {
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [helper, setHelper] = useState("")
    const [enabled, setEnabled] = useState(true)

    function handleChange(e){
        setHelper("")
        if(e.target.name === "email"){
            setEmail(e.target.value)
        }
        else if(e.target.name === "code"){
            let value = e.target.value
            value = value.replace(/[^0-9]/g, '')
            setCode(value)
        }
    }
    function handleSubmit(){
        setEnabled(false)
        if(props.onClick){
            props.onClick(false)
        }
        axios.post('/user/login', {
            email: email,
            password: code
        })
        .then((res)=>{
            //console.log(res)
            setEnabled(true)
            if(props.setUserCb){
                props.setUserCb(res.data, ()=>{
                    props.setScreen(props.screen)
                })
            }
            else{
                props.handleUser('email',res.data.email,()=>{
                    props.setScreen(props.screen)
                })
            }
        })
        .catch((error)=>{
            setEnabled(true)
            switch(error.response.status){
                case 401:
                    setHelper("Sähköposti ja/tai koodi ei ole määritetty")
                break;
                case 403:
                    setHelper("Väärä sähköposti ja/tai koodi")
                break
                default:
                    setHelper("Tuntematon virhe. Yritä uudestaan myöhemmin")
                break;
            }
        })
    }
    return (
        <Grid container
            direction="row"
            justify="center"
            alignContent="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <TextField name="email" value={email} onChange={handleChange} label="Sähköposti" variant="outlined" disabled={!enabled}/>
            </Grid>
            <Grid item xs={12}>
                <TextField name="code" value={code} onChange={handleChange} label="Koodi" variant="outlined" helperText={helper} inputProps={{maxLength:6,type:'password'}} disabled={!enabled}/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{handleSubmit()}} disabled={!enabled}>Lähetä</Button>
            </Grid>
        </Grid>
    )
}

export default Login