//For displaying question info and debugging
//Not for production use.
import React from 'react'
import { Button } from '@material-ui/core'

/*
Props:
quest
index
handleAnswer
*/
const TempQuest = (props) => {
    let randomKey = Math.floor(Math.random() * 1000)
    return (
        <div style={{border: '2px solid darkred', margin: '10px'}}>
            <p>TempQuest</p>
            
            
            
            {props.index !== 0 ? null :
            <>
                <p>{`Comment:${props.quest.comment}`}</p>
                <p>{`Title: ${props.quest.title}`}</p>
                <p>{`Subtitle: ${props.quest.subtitle}`}</p>
                <p>{`Persona: ${props.quest.persona}`}</p>
            </>
            }

            
            
            <p>{`Quest Type: ${props.quest.questType}`}</p>
            <p>{`Quest:${JSON.stringify(props.quest.quest)}`}</p>
            <Button variant="contained" style={{border:'2px solid red'}}  onClick={()=>{props.handleAnswer("quest-"+randomKey,"temp")}}>DEBUG</Button>
            {props.index !== 0 ? null :
            <>                
                <p>{`Tips:${props.quest.tips}`}</p>
            </>
            }
        </div>
    )
}


export default TempQuest