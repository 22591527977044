import React, {useState, useEffect} from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import constants from '../constants'
import questConf from '../config/questConfig.json'
import { handlePayload } from '../utils'
import QuestDialog from '../components/generic/QuestDialog'
/*
Props:
category
user
handleUser
setScreen
nextCatIndex
cats
openDia
*/
const ResultScreen = (props) => {
    let config = questConf.categories[props.category]
    const [points, setPoints] = useState({points: 0, maxPoints: 0, str:{}})
    const [panel, setPanel] = useState("Points")

    //console.log(config)
    //console.log('Open dialog ',props.openDia)
    //onMount
    useEffect(() => {
        total(props.category)
        if(config.extra){
            let extra = config.extra
            //console.log(props.openDia)
            if(config.extra === 'endMsg' && props.openDia && props.openDia === "no"){
                extra = "Points"
            }
            setPanel(extra)
        }
        //eslint-disable-next-line
    }, [])

    
    function total(cat){
        let tempPoints = 0;
        let tempMaxPoints = 0;
        let tempStr = {}
        let progCat = props.user.prog[cat]
        // let progCat = {
        //     a: "p.Ajattelija",
        //     b: "p.Ajattelija",
        //     c: "p.Puolustaja",
        //     d: "p.Diplomaatti",
        //     d: "p.Diplomaatti",
        //     d2: "p.Diplomaatti",
        //     d3: "p.Diplomaatti",
        //     e: "p.Seikkailija",
        //     f: 0,
        //     g: 1,
        //     h: "ty.Kevyt",
        //     i: "ym.Avoin",
        //     i2: "ym.Avoin"
        // }
        for(let item in progCat){
            tempMaxPoints++;
            if(progCat[item] !== 0){
                tempPoints++;
            }

            //handle string answers
            if(typeof progCat[item] === "string" || progCat[item] instanceof String){
                if(tempStr[progCat[item]]){
                    tempStr[progCat[item]] += 1
                }else{
                    tempStr[progCat[item]] = 1
                }
            }
        }

        //parse strings
        let finalStr = {}

        for(let key in tempStr){
            let keys = key.split('.')
            for(let item in tempStr){
                let items = item.split('.')

                if(!finalStr[keys[0]]){
                    finalStr[keys[0]] = keys[1]
                    break;
                }

                if(keys[0] === items[0] && tempStr[key] > tempStr[item]){
                    finalStr[keys[0]] = keys[1]
                }
            }
            
        }
        //console.log(finalStr)
        setPoints({
            points: tempPoints,
            maxPoints: tempMaxPoints,
            str: finalStr
        })
    }

    let content;
    let randomKey = Math.floor(Math.random() * 1000) //random key needed to refresh prop changes
    switch(panel){
        case "Points":
            content = <PointsPanel key={'pp-'+randomKey} setPanel={setPanel} points={points.points} maxPoints={points.maxPoints} setScreen={props.setScreen}
            category={props.category} nextCatIndex={props.nextCatIndex} cats={props.cats}
            />
        break;
        case "Details":
            content = <DetailsPanel setPanel={setPanel} links={config.links} subtitle={config.linkSubtitle}/>
        break;
        case "Persona":
            content = <PersonaPanel setPanel={setPanel} handleUser={props.handleUser} category={props.category}
            nextCatIndex={props.nextCatIndex} cats={props.cats} 
            p={points.str.p} ty={points.str.ty} ym={points.str.ym}/>
        break;
        case "PersonaDetails":
            content = <PersonaDetailsPanel setPanel={setPanel} persona={points.str.p}/>
        break;
        case "endMsg":
            content = <PointsPanel key={'pp-'+randomKey}  setPanel={setPanel} points={points.points} maxPoints={points.maxPoints} setScreen={props.setScreen}
            category={props.category} nextCatIndex={props.nextCatIndex} cats={props.cats} endMsg={config.endMsg}
            />
        break;
        default:
            content = <p>Panel not implemented</p>
        break;
    }
    let catimgsrc = ""
    if(points.str.p){
        catimgsrc = questConf.PersonaDetails[points.str.p].imgsrc
    }
    else{
        catimgsrc = config.endImgSrc
    }
    return (
        <>
        <Paper style={{backgroundColor:constants.colors.map, padding:'15px', marginBottom:'50px'}}>
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h5">{props.category}</Typography>
                </Grid>
                {panel === "Points" ? 
                <Grid item xs={12}>
                    {/* eslint-disable-next-line */}
                    <img src={catimgsrc} width={150}/>
                </Grid>
                : null}
                <Grid item xs={12} sm={8} lg={6}>
                    {content}
                </Grid>
            </Grid>
        </Paper>
        <div className="DEBUG">
            <p>Results screen</p>
            <p>{props.category}</p>
            <p>Points: {points.points} Max: {points.maxPoints} Str: {JSON.stringify(points.str)}</p>
            <p>Panel: {panel}</p>
        </div>
        </>
    )
}

/* 
Props
p
dialog
*/
export const PersonaPanel = (props)=>{
    let personaConfig = questConf.PersonaDetails[props.p]
    useEffect(() => {
        //console.log("props.p ",props.p)
        if(props.p && props.dialog === undefined){
            props.handleUser("avatar", props.p)
        }
        //eslint-disable-next-line
    }, [])

    return(
        <>
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6">
                    Seikkailuhahmosi on
                </Typography>
                <Typography variant="h6" style={{fontFamily:'grad,sans-serif', fontWeight:'bold'}}>
                    {props.p}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <img src={personaConfig.imgsrc} alt="persona" width={200}/>
            </Grid>
            {props.dialog === undefined ? <>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Tavoitteidesi perusteella tapasi yrittää voisi olla:
                </Typography>
                <Typography variant="body1" style={{fontFamily:'grad,sans-serif', fontWeight:'bold'}}>
                    {props.ty}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Perustuen intoosi yhteisössä toimimiseen yritysmuotosi voisi olla:
                </Typography>
                <Typography variant="body1" style={{fontFamily:'grad,sans-serif', fontWeight:'bold'}}>
                    {props.ym}
                </Typography>
            </Grid>
            </> : null }
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{props.setPanel("PersonaDetails")}}>Tutustu hahmoon</Button>   
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{props.setPanel("Points")}}>
                    {props.dialog === undefined ? "Seuraava" : "Sulje"}
                </Button>
            </Grid>
        </Grid>
        </>
    )
}

/*
Props
persona
*/
export const PersonaDetailsPanel = (props) => {
    let personaConfig = questConf.PersonaDetails
    let persona = personaConfig[props.persona]
    return(
        <>
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={12}> 
                <Typography variant="h5">
                    {persona.title}
                </Typography>
            </Grid>
            <Grid item xs={5} style={{display:'flex', justifyContent:'flex-end'}}>
                <img src={persona.imgsrc} alt="persona" height={150}/>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" style={{textAlign:'left'}}>
                    {persona.subtitle}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Vahvuudet
                </Typography>
                <Typography variant="body1" style={{textAlign:'left'}}>
                    {persona.strenghts}
                </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6">
                Haasteet
            </Typography>
            <Typography variant="body1" style={{textAlign:'left'}}>
                {persona.challenges}
            </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{props.setPanel("Persona")}}>
                    {props.dialog === false ? "Palaa" : "Sulje"}
                </Button>
            </Grid>
        </Grid>
        </>
    )
}

const PointsPanel = (props)=>{
    const [open, setOpen] = useState(props.endMsg ? true : false)
    const [content, setContent] = useState("")
    useEffect(() => {
        //console.log(props.endMsg)
        if(props.endMsg){
            if(props.points > (props.maxPoints / 2)){
                setContent(props.endMsg.more)
            }
            else{
                setContent(props.endMsg.less)
            }
            if(props.endMsg){
                setOpen(true)
            }
        }
        //eslint-disable-next-line
    }, [])
    function handleClose(dialog){
        if(dialog){
            setOpen(false)
        }
    }
    return(
        <>
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6">
                    Pisteesi: {props.points}/{props.maxPoints}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" style={{backgroundColor: constants.colors.third}} onClick={()=>{props.setPanel("Details")}}>Lisätietoa</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{props.setScreen(handlePayload("QuestScreen",props.category,props.nextCatIndex,props.cats))}}>Pelaa uudestaan</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={()=>{props.setScreen("CategoryScreenExtended")}}>Seuraava</Button>
            </Grid>
        </Grid>
        <QuestDialog open={open} handleClose={handleClose} content={{endgame:content}}/>
        </>
    )
}
/*
props
setPanel
links {}
subtitle
*/
export const DetailsPanel = (props)=>{
    let linkButtons = [];
    let links = props.links
    let linkSubtitle = props.subtitle
    for(let item in links){
        //console.log('fubar links', item, links[item])
        linkButtons.push(
            <Grid item xs={12} key={'link-'+item}>
                <Typography variant="body1">
                    <a href={links[item]} target="_blank" rel="noreferrer">{item}</a>
                </Typography>
            </Grid>
        )
    }
    return(
        <>
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {linkSubtitle}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        {linkButtons}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={()=>{props.setPanel("Points")}}>Takaisin</Button>
                </Grid>
            </Grid>
            <div className="DEBUG">
                <p>Subtitle: {props.subtitle}</p>
                <p>Links: {JSON.stringify(props.links)}</p>
            </div>
        </>
    )
}

export default ResultScreen