import React, {useState, useEffect} from 'react'

//components
import { Button, Grid, Zoom } from '@material-ui/core'
import QuestDialog from '../generic/QuestDialog'

//quest components
import {TempQuest, Single, SinglePersona, SliderPersona, Multi, Combine, Order, Slider} from './'

import questConfig from '../../config/questConfig.json'
/*
Props:
setScreen
category
nextCatIndex
cats
user
handleUser
*/
const QuestController = (props) => {
    const [open, setOpen] = useState(false)
    const [dialogBody, setDialogBody] = useState(null) //object {comment:String} / {tips:String} / null
    const [answer, setAnswer] = useState({}) //answer object to pass into user.prog
    const [questZoom, setQuestZoom] = useState(false) //quest objects zoom transition on mount
    const [correct, setCorrect] = useState(false) //show correct answers in quest objects

    const quests = props.quest
    //UseEffects
    //OnMount
    useEffect(() => {
        //console.log("Quest Controller mounted")
        // console.log("Quest", quests)

        for(let i in quests){
            //console.log("quest comment", quests[i].comment)
            if(quests[i].comment !== undefined){
                setDialogBody({"comment":quests[0].comment})
                break;
            }
        }
        setQuestZoom(true)
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        //console.log("dialogBody",dialogBody)
        if(dialogBody !== null){
            setOpen(true)
        }
    }, [dialogBody])

    //Functions
    function handleClose(dialog){
        //console.log("handleclose",dialog)
        if(dialog === "tips"){
            lockAnswer(true)
        }
        setOpen(false)
    }
    function handleAnswer(key,value){
        if(key && value === null){
            if(key in answer){
                setAnswer({})
            }
        }
        else{
            setAnswer({
                ...answer,
                [key]:value
            })
        }
    }
    function lockAnswer(tipper, showCorrect){
        if(!tipper && !showCorrect){
            let tipFound = false
            for(let i in quests){
                if(quests[i].tips !== undefined){
                    tipFound = true
                    setDialogBody({"tips":quests[i].tips})
                    break;
                }
            }
            //if tip wasn't found
            if(!tipFound){
                nextQuest()
            }
        }
        else{
            if(showCorrect){
                setCorrect(true)
            }
            else{
                nextQuest()
            }
        }
    }
    function nextQuest(){
        props.handleUser('prog.'+props.category,answer,()=>{
            props.nextQuest()
        })
        // setTimeout(()=>{props.nextQuest()},100)
        //props.nextQuest()
    }

    //generate quest components
    let questReq = 0;
    let content = quests.map((item,index)=>{
        questReq++;
        switch(item.questType){
            case "single":
                return <Single key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "singlePersona": 
                return <SinglePersona key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "sliderPersona":
                return <SliderPersona key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "multi":
                return <Multi key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "combine":
                return <Combine key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "order":
                return <Order key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            case "slider":
                return <Slider key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
            default:
                return <TempQuest key={'quest-'+index} quest={item} index={index} handleAnswer={handleAnswer} correct={correct}/>
        }
    })
    return (
        <>
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={3}
            style={{marginBottom:'175px'}}

        >
            <Grid item>
                <Zoom in={questZoom}>
                    <Grid container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                        spacing={3}
                        
                    >
                        {content}
                    </Grid>
                </Zoom>
            </Grid>
            
            <Grid item xs={12}>
                <Zoom in={Object.keys(answer).length >= questReq}>
                    <Button variant="contained" onClick={()=>{lockAnswer(false, !correct)}}
                        style={{backgroundColor: correct ? "#ffd474" : "#ffac94"}}
                    >{correct ?"Seuraava":"Lukitse vastaus"}</Button>
                </Zoom>
            </Grid>
        </Grid>

        <div style={{ boxShadow: questConfig.categories[props.category].footerColor ? `1px 9px 6px 19px ${questConfig.categories[props.category].footerColor}` : "none",
            position:'fixed', bottom:0,left:'0', width:'100%', height:'3%', 
            backgroundColor:questConfig.categories[props.category].footerColor,
            zIndex:'-5', display:'flex', alignItems:'center',justifyContent:'center'
            }}>
            {/* eslint-disable-next-line */}
            <img src={questConfig.categories[props.category].footerImg ? questConfig.categories[props.category].footerImg : null} 
            style={{position:'absolute',width:'50%', maxWidth:'200px', bottom:'0px', pointerEvents:'none'}}/>
        </div>
        {/* {Object.keys(answer).length >= questReq ? 
            <Button variant="contained" onClick={()=>{lockAnswer(false)}}>Lukitse vastaus</Button>
        : null} */}
        
        <QuestDialog open={open} handleClose={handleClose} content={dialogBody}/>
        <div className="DEBUG">
            {/* <p>{JSON.stringify(quests)}</p> */}
            <p>Answer: {JSON.stringify(answer)}</p>
            <p>Correct: {correct.toString()}</p>
            {/* <Button onClick={()=>{lockAnswer(false)}} variant="contained">lock answer</Button> */}
        </div>
        </>
    )
}


export default QuestController