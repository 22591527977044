import React, { useState, useEffect } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import constants from '../../constants';
import { shuffle } from '../../utils';
/*
Props
quest
index
handleAnswer
correct
*/
const Combine = (props) => {
    const [state, setState] = useState(initData(props.quest.quest.opt))
    useEffect(() => {
        //console.log('state:',state)
        let val = calcAnswer()
        props.handleAnswer(props.quest._id,val)
        //eslint-disable-next-line
    }, [state])
    
    function calcAnswer(){
        let ansVal = 1
        for(let i in state.columns['column-0'].cardIds){
            if(checkColAnswer(state.columns['column-0'].cardIds[i], i) === 0){
                ansVal = 0
                break;
            }
        }
        //console.log(ansVal)
        return ansVal
    }

    function checkColAnswer(card, index){
        //console.log(card,index)
        let cardId = card.split('-')[1]
        if(cardId === index){
            return 1
        }
        else{
            return 0
        }
    }

    function initData(opt){
        let cards = {}
        let cardArr = []
        //console.log(opt)
        for(let i in opt){
            cards['card-'+i] = { id: 'card-'+i, content: opt[i], correct:i}
            // cards.push({ id: 'card-'+i, content: answ[i] })
            cardArr.push('card-'+i)
        }
        cardArr = shuffle(cardArr)
        let columns = {
            'column-0':{
                id:'column-0',
                title:'',
                cardIds: cardArr
            }
        }
        return {
            cards: cards,
            columns:columns
        }
    }

    function onDragEnd(result){
        const {destination, source, draggableId} = result

        if(!destination){
            return;
        }

        if(
            destination.droppableId === source.draggableId &&
            destination.index === source.index
        ){
            return;
        }

        const start = state.columns[source.droppableId]
        const finish = state.columns[destination.droppableId]

        if(start === finish){
            const newCardIds = Array.from(start.cardIds)
            newCardIds.splice(source.index, 1)
            newCardIds.splice(destination.index, 0, draggableId)
    
            const newColumn = {
                ...start,
                cardIds: newCardIds,
            }
    
            setState({
                ...state,
                columns:{
                    ...state.columns,
                    [newColumn.id]: newColumn,
                },
            });
            return;
        }
        else{
            const startCardIds = Array.from(start.cardIds)
            startCardIds.splice(source.index, 1)
            const newStart = {
                ...start,
                cardIds: startCardIds,
            }
    
            const finishCardIds = Array.from(finish.cardIds)
            finishCardIds.splice(destination.index, 0, draggableId)
    
            const newFinish = {
                ...finish,
                cardIds: finishCardIds,
            }

            setState({
                ...state,
                columns:{
                    ...state.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            })
            return;
        }

    }
    return (
        <Grid item xs={12}>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {props.quest.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {props.quest.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={12}>
                        <Column column={state.columns['column-0']} 
                        cards={state.columns['column-0'].cardIds.map(cardId => state.cards[cardId])}
                        disabled={props.correct}/>
                    </Grid>
                </Grid>
            </DragDropContext>
        </Grid>
    )
}

/*
props
column
cards
*/
const Column = props => {
    return (
        <Grid item xs={12} 
            style={{minHeight:'100px', width:'100%'}}
        
        >
            <Typography variant="h6">{props.column.title}</Typography>
            <Droppable droppableId={props.column.id}
                isDropDisabled={props.cards.length > 0 && props.column.id !== 'column-0' ? true : false}
            >
                {(provided)=>(
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                        innerRef={provided.innerRef}
                        {...provided.droppableProps}
                        style={{border:'3px dashed #4FC77B', borderRadius:'25px', minHeight:'70px', marginBottom:'10px'}}
                    >
                        {props.cards.map((card, index) => <AnsCard key={card.id} item={card} index={index} disabled={props.disabled} colVal={index}/>)}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </Grid>
    )
}

/* 
props
item
index
colVal
*/
const AnsCard = (props) => {

    function getColor(card,col){
        //console.log(props.item.correct, props.colVal)
        if(props.disabled){
            if(props.item.correct === props.colVal.toString()){
                return "#50c47c"
            }
            else{
                return "#ffac94"
            }
        }
        else{
            return constants.colors.map
        }
    }

    return (
        <Draggable 
            draggableId={props.item.id} 
            index={props.index}
            isDragDisabled={props.disabled}
        >
            {(provided)=>(
                <Grid item xs={12}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    innerRef={provided.innerRef}
                >
                    <Paper
                        style={{backgroundColor:getColor(), borderRadius:'17px', padding:'15px'}}
                    >
                        <Typography>{props.item.content}</Typography>
                    </Paper>
                </Grid>
            )}
        </Draggable>
    )
}

export default Combine