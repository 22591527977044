import React, {useState} from 'react'
//components
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Grid} from '@material-ui/core'
//icons
import {
    Map as MapIcon,
    AccountCircle as AccountIcon,
    Info as InfoIcon
} from '@material-ui/icons'
import Credits from './Credits'

const Topbar = (props) => {
    const [menuvis, setMenuvis] = useState(null)
    // For handling Credits MenuItem -->
    //const [anchorEl, setAnchorEl] = React.useState(null)
    const [showCredits, setShowCredits] = useState(false)

    // const handleClick2 = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    
    const handleClose2 = () => {
        //setAnchorEl(null);
    };
    
    const openCreditsDialog = () => {
        setShowCredits(true);
        handleClose2();
    };
    
    const closeDialog = () => {
        setShowCredits(false);
    };
    // ----->//

    function handleClose(changeScreen){
        props.setScreen(changeScreen)
        setMenuvis(null)
    }
    return (
        <>
        <AppBar position="fixed" style={{zIndex:25}}>
            <Toolbar>
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Grid container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={1} style={{marginLeft:'-5px', marginRight:'5px'}}>
                                <IconButton onClick={()=>{openCreditsDialog()}} edge="start" color="inherit" aria-label="menu">
                                    <InfoIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={11} sm={6} md={4} lg={3}>
                                <Typography variant="h6" onClick={()=>{openCreditsDialog()}} style={{cursor:'pointer'}}>
                                    Uusyrittäjä-peli
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        
                    </Grid>
                    <Grid item xs={6}>
                    {props.enabled ? 
                        <Grid container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            {/* <Grid item>
                                <IconButton onClick={()=>{props.setScreen("UserScreen")}} edge="start" color="inherit" aria-label="menu">
                                    <AccountIcon/>
                                </IconButton>
                            </Grid> */}

                            <Grid item>
                                <IconButton onClick={()=>{handleClose("CategoryScreenExtended")}} edge="start" color="inherit" aria-label="menu">
                                    <MapIcon/>
                                </IconButton>
                            </Grid>
                            {/* <Grid item>
                                <IconButton onClick={(e)=>{setMenuvis(e.currentTarget)}} edge="start" color="inherit" aria-label="menu">
                                    <BugIcon />
                                </IconButton>
                            </Grid> */}
                            <Grid item>
                                <Menu anchorEl={menuvis} keepMounted open={Boolean(menuvis)} onClick={()=>{setMenuvis(null)}}>
                                    <MenuItem onClick={()=>{handleClose("FrontScreen")}}>Front</MenuItem>
                                    <MenuItem onClick={()=>{handleClose("CategoryScreen")}}>Category</MenuItem>
                                    <MenuItem onClick={()=>{handleClose("UserScreen")}}>User</MenuItem>
                                    <MenuItem onClick={()=>{handleClose("CategoryScreenExtended")}}>Category extended</MenuItem>
                                    <MenuItem onClick={openCreditsDialog}>Credits</MenuItem>
                                    <MenuItem onClick={()=>{handleClose("LoginScreen")}}>Login</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    : null}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Credits open={showCredits} onClose={closeDialog}/>
        </>
    )
}

export default Topbar
