import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'

import Login from '../components/user/Login'
/*
Props
setScreen
user
setUserCb
handleUser
isLogged
destination
register
*/
const LoginScreen = (props) => {
    const [enabled, setEnabled] = useState(true)
    useEffect(() => {

        // if(props.isLogged()){
        //     props.setScreen(props.destination ? props.destination : "FrontScreen")
        // }
        //eslint-disable-next-line
    }, [])

    return (
        <Grid container
            direction="row"
            justify="center"
            alignContent="center"
            spacing={2}
        >
            
            <Grid item xs={12}>
                <Typography variant="h5">
                    Kirjaudu sisään
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Login onClick={()=>{setEnabled(false)}} handleUser={props.handleUser} setUserCb={props.setUserCb} setScreen={props.setScreen} screen={props.destination ? props.destination : "FrontScreen"}/>
            </Grid>
            {props.register === "disabled" ? null : 
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={()=>{props.setScreen("RegisterScreen")}} disabled={!enabled}>Rekisteröidy</Button>
                </Grid>
            }
        </Grid>
    )
}

export default LoginScreen