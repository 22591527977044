import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import { handlePayload } from '../utils'
import questConfig from '../config/questConfig.json'
import Sign from '../components/generic/Sign'
import constants from '../constants'
/*
Props:
setScreen
category
nextCatIndex
cats

*/
const IntroScreen = (props) => {
    const [scroll, setScroll] = useState(window.pageYOffset)
    //mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return ()=>{
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    function onScroll(){
        setScroll(window.pageYOffset)
    }
    let object = questConfig.categories[props.category]
    let imgSrc = object.imgSrc
    return (
        <>
        <Grid container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={0}
            style={{marginBottom:'90px'}}
        >
            <Grid item>
                <img alt="intro" style={{maxWidth:'100%'}} width={400} src={imgSrc}/>
            </Grid>
            <Grid item>
                <Sign title={props.category} content={object.bodyText}/>
            </Grid>
            <Grid item>
                <Button variant="contained" style={{backgroundColor:"#FFD271"}} onClick={()=>{props.setScreen(handlePayload("QuestScreen",props.category,props.nextCatIndex,props.cats))}}>Pelaa</Button>
            </Grid>
        </Grid>
        <div style={{
            backgroundColor:constants.colors.primary.light,
            position:'fixed', 
            width:'100%',
            height:`calc(69% + ${scroll * 0.47}px)`,
            bottom:'0',
            left:'0',
            zIndex:'-2'
        }}/>
        </>
    )
}

export default IntroScreen