import React from 'react'
import { Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Grid } from '@material-ui/core'

const QuestDialog = (props) => {
    let open = props.open
    let dialog = props.content ? Object.keys(props.content)[0] : ""
    let dialogText;
    let content = props.content ? props.content[dialog] : null

    switch(dialog){
        case "comment":
            dialogText = "Velhon kommentti"
        break;
        case "tips":
            dialogText = "Velhon vinkki"
        break;
        case "endgame":
            dialogText = "Suorituksesi"
        break;
        default:
            dialogText = dialog
    }

    return (
        <Dialog
                open={open}
                onClose={()=>{props.handleClose(dialog)}}
            >
            <Container style={{backgroundImage: 'url("/images/Yrityspeli_kuvitukset_2.0_Vuori.png")', backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat: 'no-repeat'}}>
                <DialogTitle style={{fontFamily:'grad,sans-serif', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h5" style={{fontFamily:'grad,sans-serif'}}>
                                {dialogText}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{fontFamily:'grad,sans-serif', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width:'20vh'}} src={"/images/Yrityspeli_kuvitukset_2.0_Velho.png"} alt=""/>
                </DialogContent>
                <DialogContent>
                    <Typography variant="body1" style={{fontFamily:'grad,sans-serif', textAlign:'center', color:'black', backgroundColor: "white", borderRadius: "12px", padding:'3px'}}>
                        {content ? content : null}                      
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{props.handleClose(dialog)}}>Jatka</Button>
                </DialogActions>
            </Container>
        </Dialog>
    )
}

export default QuestDialog