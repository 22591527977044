import React, { useState, useEffect } from 'react'
import { Grid, Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
/*
Props
quest
index
handleAnswer
correct
*/
const Multi = (props) => {
    const [state, setState] = useState({})

    useEffect(() => {
        if(getPickCount("")){
            let val = calcScore(state, props.quest.quest.answ)
            props.handleAnswer(props.quest._id,val)
        }
        else{
            props.handleAnswer(props.quest._id,null)
        }
        //eslint-disable-next-line
    }, [state])
    function handleChange(e){
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    }
    function calcScore(obj, answ){
        if(getPickCount("")){
            let point = 1;
            for(let item in obj){
                if(!answ.includes(item) && obj[item]){
                    point = 0
                    break;
                }
            }
            return point
        }
    }
    function getColor(item,correct){
        let answ = props.quest.quest.answ
        if(correct){
            //console.log(item, answ, state)
            //if item is correctly selected
            if(answ.includes(item)){
                return "#50c47c" //green
            }
            else{
                return "#ffac94" //red
            }
        }
        else{
            return null
        }
    }
    function getPickCount(picker){
        let pickLimit = Object.keys(props.quest.quest.answ).length
        let picked = 0

        if(props.correct){
            return true
        }
        for(let item in state){
            if(state[item] === true){
                picked++;
            }
        }
        if(picked >= pickLimit){
            if(state[picker]){
                return false
            }
            else{
                return true
            }
        }
        else{
            return false
        }
    }
    function loadBoxes(){
        let boxes = props.quest.quest.opt.map((item,index)=>{
            
            return <FormControlLabel key={'multi-'+index}
                style={{
                    display:'flex', flexDirection:'row',justifyContent:'flex-start',alignItems:'center',
                    textAlign:'left', marginBottom:'15px'
                }}
                control={
                    <Checkbox
                        checked={state[index]}
                        onChange={handleChange}
                        name={item}
                        color="primary"
                        disabled={getPickCount(item)}
                        style={{color:getColor(item, props.correct)}}
                    />
                }
                label={item}
            />
        })
        return boxes
    }

    return (
        <>
        <Grid item xs={12}>
            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography variant="h5">{props.quest.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{props.quest.subtitle}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {state ? loadBoxes() : null}
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default Multi