import React, {useState, useEffect} from 'react'
import { Grid, FormControl, Typography, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'

/* Props
quest
index
handleAnswer
*/

const SinglePersona = (props) => {
    const [value, setValue] = useState('')
    
    useEffect(() => {
        if(value !== ''){
            //console.log(value)
            props.handleAnswer(props.quest._id,props.quest.quest.answ[props.quest.quest.opt.indexOf(value)])
        }
        //eslint-disable-next-line
    }, [value])

    const handleChange = (e)=>{
        setValue(e.target.value)
    }

    function loadButtons(){
        let buttons = props.quest.quest.opt.map((item,index)=>{
            return <FormControlLabel key={'radio-'+index} 
            style={{
                display:'flex', flexDirection:'row', justifyContent:'flex-start', 
                alignItems:'center', textAlign:'left', marginBottom:'5px',
                backgroundColor:props.correct ? "#4FC77B" : '#ffd474', borderRadius:'25px', padding:'10px'
            }}
            value={item} control={<Radio/>} 
            label={item} disabled={props.correct}/>
        })
        return buttons
    }
    return (
        <Grid item xs={12}>
            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography variant="h5">{props.quest.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{props.quest.subtitle}</Typography>
                </Grid>
                <Grid item xs={11} sm={12}>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">{props.quest.subtitle ? props.quest.subtitle :''}</FormLabel> */}
                        <RadioGroup name="radio-group" value={value} onChange={handleChange}>
                            {loadButtons()}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            
            <div className="DEBUG">
                <p>Single Persona</p>
                
                
                
                {props.index !== 0 ? null :
                <>
                    <p>{`Comment:${props.quest.comment}`}</p>
                    <p>{`Title: ${props.quest.title}`}</p>
                    <p>{`Subtitle: ${props.quest.subtitle}`}</p>
                    <p>{`Persona: ${props.quest.persona}`}</p>
                </>
                }

                
                
                <p>{`Quest Type: ${props.quest.questType}`}</p>
                <p>{`Quest:${JSON.stringify(props.quest.quest)}`}</p>

                {props.index !== 0 ? null :
                <>                
                    <p>{`Tips:${props.quest.tips}`}</p>
                </>
                }
            </div>
        </Grid>
    )
}


export default SinglePersona