import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Register from '../components/user/Register'

const RegisterScreen = (props) => {

    return (
        <Grid container
            direction="row"
            justify="center"
            alignContent="center"
            spacing={2}
        >
            
            <Grid item xs={12}>
                <Typography variant="h5" style={{paddingTop: '12px'}}>
                    Rekisteröityminen
                </Typography>
                <Typography variant="h6" style={{paddingTop: '12px'}}>
                    (vapaaehtoinen)
                </Typography>
                <img style={{width: "20vh"}} src={"/images/Yrityspeli_kuvitukset_2.0_Velho.png"} alt=""/>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
                <Typography variant="body1" style={{paddingBottom: '10px', paddingLeft: '14px', paddingRight: '14px'}}>
                    Rekisteröidy antamalla sähköpostiosoitteesi. Lähetämme sinulle koodin, jonka avulla pääset halutessasi kirjautumaan peliin myöhemmin uudelleen.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Register handleUser={props.handleUser} setScreen={props.setScreen} screen="FrontScreen"/>     
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">(Sähköpostiosoitetta käytetään vain kirjautumiseen. Tietoja ei välitetä pelin ulkopuolelle.)</Typography>
            </Grid>
        </Grid>
    )
}

export default RegisterScreen
