import React from 'react'
import { Typography } from '@material-ui/core'

const NoConnection = () => {
    return (
        <Typography>
            Valitettavasti peliin ei saatu yhteyttä. Yritä uudestaan myöhemmin
        </Typography>
    )
}

export default NoConnection