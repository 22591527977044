import React, { useState } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import axios from 'axios'

// Tätä en uskaltanut muuttaa paljoakaan (kopypastettu Login.js:stä), kun en pysy täysin kärryillä siitä mitä kaikkea tässä tapahtuu :)
const Register = (props) => {
    const [email, setEmail] = useState("")
    const [enabled, setEnabled] = useState(true)

    function handleChange(e){
        if(e.target.name === "email"){
            setEmail(e.target.value)
        }
        
    }
    function handleSubmit(){
        setEnabled(false)
        if(props.onClick){
            props.onClick(false)
        }

        axios.post('/user/register', {
            email: email
        })
        .then((res)=>{
            console.log(res)
            setEnabled(true)
            props.handleUser('email',res.data.email,()=>{
                props.setScreen("LoginScreen")
            })
        })
        .catch((err)=>{
            setEnabled(true)
        })
    }
    return (
        <Grid container
            direction="row"
            justify="center"
            alignContent="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <TextField name="email" value={email} onChange={handleChange} label="Sähköposti" variant="outlined" disabled={!enabled}/>
            </Grid>
            <Grid item xs={4} sm={3} md={2} lg={1}>
                <Button variant="contained" color="primary" onClick={()=>{handleSubmit()}} disabled={!enabled}>Lähetä</Button>
            </Grid>
            <Grid item xs={4} sm={3} md={2} lg={1}>
                <Button variant="contained" color="primary" onClick={()=>{props.setScreen("CategoryScreen")}} disabled={!enabled}>Ohita</Button>
            </Grid>
        </Grid>
    )
}

export default Register