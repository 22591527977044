import React from 'react';
import './App.css';
import '@fontsource/roboto';
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';

import constants from './constants.js';

//components
import DataContainer from './components/generic/DataContainer';

//theme
const theme = createMuiTheme({
  palette:{
    primary:{
      main: constants.colors.primary.main,
      light: constants.colors.primary.light,
      dark: constants.colors.primary.dark
    }
  },
  typography:{
    fontFamily: "brother-1816,sans-serif"
  }
})
document.body.style = `background: ${constants.colors.background}`

const App = () => {
  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          <DataContainer/>
        </ThemeProvider>
    </div>
  );
}

export default App;
