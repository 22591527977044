const constants = {
    colors:
    {
        background: "#C1E3DC",
        introButton: "#FFD271",
        primary:{
            main:"#4FC77B",
            light: "#72d295",
            dark: "#378b56"
        },
        secondary: "#E6E2D8",
        third: "#FFAD94",
        fourth: "#FFD271",
        map: '#FFE79E',
        grey: '#9c9c9c'
    }
}

export default constants