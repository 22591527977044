//collection of useful functions

export function handlePayload(){
    let string = ""
    for(let i in arguments){
        string += arguments[i]
        if(parseInt(i)+1 !== arguments.length){
            string += '.'
        }
    }
    return string
    //return screen+'.'+cat+'.'+nextCatIndex+'.'+cats
}

export function to2Decimal(value){
    return Math.round((value * 0.01) * 100) / 100
}

export function isEmpty(object){
    return Object.keys(object).length === 0
}

export function shuffle(array){
    var currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export function solvePoints(prog){
    let p = 0;
    let max = 0;
    for(let item in prog){
        if(typeof prog[item] === "string" || prog[item] > 0){
            p++;
        }
        max++
    }
    
    return [p,max]
}