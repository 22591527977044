import React, {useState, useEffect} from 'react'
import { Grid, Typography, Slider as MatuiSlider } from '@material-ui/core'
import constants from '../../constants'
/*
Props:
quest
index
handleAnswer
*/
const Slider = (props) => {
    let min = props.quest.quest.min
    let max = props.quest.quest.max
    let step = props.quest.quest.step
    let answ = props.quest.quest.answ
    let tags = props.quest.quest.tags

    const [value, setValue] = useState(min)
    useEffect(() => {
        //console.log(value)
        let val = handleScore(value)
        props.handleAnswer(props.quest._id,val)
        //eslint-disable-next-line
    }, [value])

    function handleScore(val){
        let ansKeys = answ.split(':')
        //console.log(ansKeys)
        switch(ansKeys[0]){
            case "gt":
                if(value > ansKeys[1]){
                    return 1
                }
            break;
            case "lt":
                if(value < ansKeys[1]){
                    return 1
                }
            break;
            case "gteql":
                if(value >= ansKeys[1]){
                    return 1
                }
            break;
            case "lteql":
                if(value <= ansKeys[1]){
                    return 1
                }
            break;
            case "eql":
                if(value === parseFloat(ansKeys[1])){
                    return 1
                }
            break;
            default:
                if(ansKeys[1] > value){
                    return 1
                }
        }
        return 0
    }
    function handleChange(e,newValue){
        setValue(newValue)
    }
    function getColor(value){
        if(props.correct){
            if(handleScore(value) === 1){
                return constants.colors.primary.main
            }
            else{
                return "#ffac94"
            }
        }
        else{
            return constants.colors.primary.main
        }
    }
    return (
        <Grid item xs={12}>
            <Grid container
                direction="column"
                justify="flex-start"
                alignContent="center"
                spacing={3}
            >
                <Grid item xs={12}>
                    {props.index !== 0 ? null : 
                        <Typography variant="h5">{props.quest.title}</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{props.quest.subtitle}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="column"
                        justify="center"
                        alignContent="center"
                    >
                        <Grid item xs={7} sm={12} style={{width:'100%'}}>
                            <MatuiSlider
                                onChange={handleChange}
                                step={step}
                                min={min}
                                max={max}
                                marks={tags}
                                valueLabelDisplay="auto"
                                track={false}
                                disabled={props.correct}
                                style={{color:getColor(value)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Slider