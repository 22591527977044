import React, {useEffect, useState} from 'react'
import {Button, Grid} from '@material-ui/core'
import Sign from '../components/generic/Sign'
import constants from '../constants'
import axios from 'axios'
/*
Props
setScreen
isLogged
*/
const FrontScreen = (props) => {
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        //when component mounts
        axios.get('/user')
        .then((res)=>{
            setDisabled(false)
            if(res.status === 200){
                props.setScreen("CategoryScreen")
            }
        })
        .catch((err)=>{
            setDisabled(false)
        })
        return () => {
            //when component unmounts
            //console.log("unmount")
        }
        //eslint-disable-next-line
    }, [])
    

    return (
        <div style={{
            backgroundImage:'url(/images/Yrityspeli_kuvitukset_2.0_Vuori.png)',
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            backgroundPosition:'center',
            width:'100%',
            height:'80vh',
            paddingTop:'10px'
        }}>
            <Grid container
                direction="column"
                justify="space-around"
                alignItems="center"
                style={{height:'100%',paddingTop:'20px'}}
            >
                <Grid item style={{margin:'10px', width:'100%', maxWidth:'40vh'}}>
                    <img alt="uusyrityskeskus" src="https://uusyrityskeskus.fi/wp-content/themes/uusyrityskeskus/dist/images/uusyrityskeskus_logo.svg"
                        style={{width:'100%'}}
                    />
                </Grid>


                <Grid item>
                    <Grid container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={10}>
                            <Sign titleVariant="h5" title="Tervetuloa valloittamaan Yrittäjyyden vuorta!"/>
                        </Grid>
                        <Grid item xs={11} sm={7} lg={5}>
                            <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={1}
                            >
                                {/* <Grid item xs={6}>
                                    <Button variant="contained" style={{backgroundColor:constants.colors.introButton}}
                                        onClick={()=>{props.setScreen("RegisterScreen")}}
                                        disabled={disabled}
                                    >Uusi pelaaja</Button>
                                </Grid> */}
                                {/* <Grid item xs={6}>
                                    <Button variant="contained" style={{backgroundColor:constants.colors.third}}
                                        onClick={()=>{props.setScreen( props.isLogged() ? "CategoryScreen" : "LoginScreen.CategoryScreen")}}
                                        disabled={disabled}
                                    >Jatkan peliä</Button>
                                </Grid> */}
                                <Grid item xs={6}>
                                    <Button variant="contained" style={{backgroundColor:constants.colors.introButton}}
                                        onClick={()=>{props.setScreen("CategoryScreen")}}
                                        disabled={disabled}
                                    >Pelaa</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </div>

        // <Grid 
        //     container
        //     direction="column"
        //     justify="flex-start"
        //     alignItems="center"
        //     spacing={2}
        //     style={{margin:0, width:'100%'}}
        // >
        //     <Grid item>
        //         <p>Etusivu</p>
        //         <p>
        //             Ominaisuudet<br/>
        //             * Tervehdys viesti<br/>
        //             * Linkki Käyttäjän profiiliin (rekisteröinti/kirjautuminen)<br/>
        //             * Linkki Peliin<br/>
        //         </p>
        //     </Grid>
        //     <Grid item xs={12}>
        //         <Button onClick={()=>{props.setScreen("CategoryScreen")}} variant="contained" color="primary">Uusi pelaaja</Button>
        //     </Grid>

        //     <Grid item xs={12}>
        //         <Button onClick={()=>{props.setScreen( props.isLogged() ? "CategoryScreen" : "LoginScreen")}} variant="contained" color="primary">Jatkan peliä</Button>
        //     </Grid>
        // </Grid>
    )
}

export default FrontScreen
