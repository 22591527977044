import React, {useState, useEffect} from 'react'
import { Grid, FormControl, Typography, FormControlLabel, RadioGroup, Radio, Zoom } from '@material-ui/core'
/*
Props:
quest
index
handleAnswer
correct
*/
const Single = (props) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if(value !== ''){
            let ansVal = checkAnswer(value, props.quest.quest.answ)
            props.handleAnswer(props.quest._id,ansVal)
        }
        //eslint-disable-next-line
    }, [value])
    const handleChange = (e)=>{
        
        setValue(e.target.value)
    }

    function checkAnswer(selection, answer){
        if(selection === answer){
            return 1
        }
        else{
            return 0
        }
    }
    function correctColor(item){
        if(props.correct){
            if(checkAnswer(item,props.quest.quest.answ) > 0 && item === value){
                return "#50c47c" //green
            }
            else if(checkAnswer(item,props.quest.quest.answ) === 0 && item === value){
                return "#ffac94" //red
            }
            else{
                return "#ffd474" //yellow
            }
        }
        else{
            return "#ffd474"
        }
    }
    function loadButtons(){
        let buttons = props.quest.quest.opt.map((item,index)=>{
            return (
                <Grid item xs={12} style={{width:'100%'}} key={'single-'+index}>
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <FormControlLabel value={item} control={<Radio/>} label={item} disabled={props.correct} 
                                style={{
                                    display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center', 
                                    textAlign:'left',
                                    backgroundColor: correctColor(item),
                                    borderRadius:'25px', paddingBottom:'10px', paddingTop:'10px', marginLeft:'-10px', marginRight:'-10px'
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Zoom in={props.correct}>
                                <img src={checkAnswer(item,props.quest.quest.answ) === 1 ? '/images/Check.png' : '/images/Cross.png'} alt="checkmark" width={32} height={32}/>
                            </Zoom>
                        </Grid>  
                    </Grid>
                </Grid>
            )
            // return <Grid item xs={12} key={'radio-'+index} style={{border:'1px solid red'}}>
            //         <Grid container
            //             direction="row"
            //             justify="space-between"
            //             alignItems="center"
            //             style={{border:'1px solid red'}}
            //         >
            //             <Grid item xs={5} sm={11}>
            //                 <FormControlLabel value={item} control={<Radio/>} label={item} disabled={props.correct}/>
            //             </Grid>
            //             <Grid item xs={1}>
            //                 <Zoom in={props.correct}>
            //                     <img src={checkAnswer(item,props.quest.quest.answ) === 1 ? '/images/Check.png' : '/images/Cross.png'} width={32} height={32}/>
            //                 </Zoom>
            //             </Grid>
            //         </Grid>
            //     </Grid>
        })
        
        return buttons
    }
    return (
        <>
        <Grid item xs={12} >
            <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    
                >
                <Grid item xs={12} >
                    <Typography variant="h5">{props.quest.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{props.quest.subtitle}</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={5} style={{width:'100%'}}>
                    <FormControl component="fieldset" style={{width:'100%'}}>
                        {/* <FormLabel component="legend">{props.quest.subtitle ? props.quest.subtitle :''}</FormLabel> */}
                        <RadioGroup name="radio-group" value={value} onChange={handleChange} >
                            <Grid container
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                style={{width:'100%'}}
                            >
                                {loadButtons()}
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        {/* <div className="DEBUG" style={{border: '1px solid black', margin: '10px'}}>
            <p>Single</p>
            
            
            {props.index !== 0 ? null :
            <>
                <p>{`Comment:${props.comment}`}</p>
                <p>{`Title: ${props.title}`}</p>
                <p>{`Subtitle: ${props.subtitle}`}</p>
                <p>{`Persona: ${props.persona}`}</p>
            </>
            }

            
            
            <p>{`Quest Type: ${props.questType}`}</p>
            <p>{`Quest:${JSON.stringify(props.quest)}`}</p>

            {props.index !== 0 ? null :
            <>                
                <p>{`Tips:${props.tips}`}</p>
            </>
            }
        </div> */}
        </>
    )
}


export default Single