import React, {useState, useEffect} from 'react'
import { Grid, Slider, Typography } from '@material-ui/core'
import { to2Decimal } from '../../utils'
/*
Props:
quest
index
handleAnswer
*/
const SliderPersona = (props) => {
    const [value, setValue] = useState(50)

    useEffect(() => {
        let val = handleScore(props.quest.quest.opt,props.quest.quest.answ,value)
        props.handleAnswer(props.quest._id,val)
        //eslint-disable-next-line
    }, [value])
    function handleChange(e,newValue){
        if(e.cancelable){
            e.preventDefault();
        }
        setValue(newValue)
    }
    function generateTags(array){
        let tags = []
        let step = 100 / (array.length - 1)
        for(let i in array){
            tags[i] = {
                value: Math.round(step*i),
                label: array[i]
            }
        }
        return tags
    }
    function handleScore(options,answer,val){
        let opt = []
        opt.push(0)
        opt = opt.concat(options)
        opt.push(100)

        const compareBetween = (a,b,input)=>{
            if(input > a && input <= b){
                return true
            }
            else{
                return false
            }
        }

        // val = val * 0.01
        // val = Math.round(val * 100) / 100
        val = to2Decimal(val)
        let found = 0
        for(let i = 0; i < opt.length - 1; i++){
            if(compareBetween(opt[i],opt[i+1],val)){
                found = i
                break;
            }
        }
        return answer[found]
    }

    let marks = generateTags(props.quest.quest.tags)
    return (
        <>
        <Grid item xs={12}>
            <Typography variant="h5" id="slider" style={{visibility: props.index === 0 ? "visible" : "hidden"}}>
                {props.quest.title}
            </Typography>
            <Typography variant="h6" id="slider" 
            style={{visibility: props.index === 0 ? "visible" : "hidden",
            marginBottom: props.index === 0 ? "0px" : "-55px"}}
            >
                {props.quest.subtitle}
            </Typography>
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={7} sm={12} style={{width:'100%'}}>
                    <Slider value={value} 
                        min={0} 
                        max={100} 
                        onChange={handleChange}
                        aria-labelledby="slider"
                        marks={marks}
                        step={10}
                        track={false}
                        disabled={props.correct}
                    />

                </Grid>
            </Grid>
        </Grid>
        <div className="DEBUG">
            <p>Value: {value}</p>
            <p>Answer: {handleScore(props.quest.quest.opt,props.quest.quest.answ,value)}</p>
        </div>
        </>
    )
}


export default SliderPersona