import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import constants from '../../constants'
import questConfig from '../../config/questConfig.json'
import { solvePoints } from '../../utils'

const CategoryButton = (props) => {
    let index = props.index + 1
    let prog = props.prog
    function oddEven(num){
        return num % 2 === 0;
    }
    function getPoints(){
        let [p,max] = solvePoints(prog)
        return `Pisteet: ${p}/${max}`
    }
    function handleClick(){
        if(!props.disabled){
            props.handleClick()
        }
    }
    let color = constants.colors.introButton;
    let pointer = 'pointer';
    let bbm = 'normal'
    if(props.disabled){
        color = constants.colors.grey
        pointer = 'auto'
        bbm = 'luminosity'
    }
    return (
        <Grid container
            direction={oddEven(index) ? 'row' : 'row-reverse'}
            justify="space-between"
            align="center"
        >
            <Grid item xs={8} style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <Grid container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                        backgroundColor:color,
                        borderRadius:'25px 25px 25px 25px',
                        padding:'10px',
                        cursor:pointer
                    }}
                    onClick={() => handleClick()}
                >
                    <Grid item>
                        <Typography variant="h6">{index}. {props.item}</Typography>
                    </Grid>
                    {props.prog ? 
                    <Grid item>
                        <Typography variant="body1">{getPoints()}</Typography>
                    </Grid>
                    : null}
                </Grid>
            </Grid>
            <Grid item xs={4} onClick={() => handleClick()} style={{
                    height:'110px',
                    width:'110px',
                    backgroundColor:'#fff',
                    borderRadius:'50%',
                    display:'inline-block',
                    border:`7px solid ${color}`,
                    backgroundImage:`url(${questConfig.categories[props.item].imgSrc})`,
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    backgroundPosition:'center',
                    cursor:pointer,
                    backgroundBlendMode:bbm
                }}>
            </Grid>
        </Grid>
    )
}

export default CategoryButton