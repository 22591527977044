import React, {useState, useEffect} from 'react'
import {
    Grid, 
    Dialog, DialogContent, DialogTitle,
    CircularProgress,
    Button,
    Zoom
} from '@material-ui/core'
import axios from 'axios'
import Login from '../components/user/Login'
import Profile from '../components/user/Profile'
//Props
//  setScreen
//  user
//  handleUser
// setUserCb
const UserScreen = (props) => {
    const [open, setOpen] = useState(false)
    const [profile, setProfile] = useState(null)
    const [zoomIn, setZoomIn] = useState(false)
    
    useEffect(() => {
        let mounted = true

        if(props.user['email'] === undefined){
            if(mounted){
                setOpen(true)
            }
        }
        else{
            if(mounted){
                axios.get('/user')
                .then((res)=>{
                    //console.log(res)
                    if(res.status === 200){
                        if(mounted){
                            setProfile(props.user)
                            setZoomIn(true)
                        }
                    }
                    else{
                        if(mounted){
                            setOpen(true)
                        }
                    }
                })
                .catch((err)=>{
                    //console.log(err)
                    if(mounted){
                        setOpen(true)
                    }
                })
            }
        }

        return () => {
            mounted = false
        }
        //eslint-disable-next-line
    }, [])
    function handleClose(reason){
        if(typeof reason === "string"){
            setOpen(false)
            setProfile(props.user)
        }
    }
    function logout(){
        axios.get('/user/logout')
        .then(()=>{
            props.setUserCb({prog:{}, avatar: ""},()=>{
                props.setScreen("FrontScreen")
            })
        })
    }
    return (
        <>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
        >
            {profile === null ? <CircularProgress/> : <>
            <Grid item xs={12}>
                <Button onClick={()=>{logout()}} variant="contained" color="primary">Kirjaudu ulos</Button>
            </Grid>
            <Zoom in={zoomIn}>
                <Grid item xs={12} sm={8} lg={6}>
                    <Profile profile={profile}/>
                </Grid>
            </Zoom>
            </>}
        </Grid>

        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Kirjaudu sisään</DialogTitle>
            <DialogContent>
                <Grid container
                    direction="column"
                    justify="center"
                    alignContent="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Login handleUser={props.handleUser} setUserCb={props.setUserCb} setScreen={(screen)=>{handleClose(screen)}} screen="LoggedIn"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={()=>{props.setScreen("RegisterScreen")}} variant="contained" color="primary">Rekisteröidy</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={()=>{props.setScreen("CategoryScreen")}} variant="contained" color="primary">Takaisin</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default UserScreen
