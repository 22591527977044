//Main component for the app
//Handles data and screen changes
import React, {useState,useEffect, useRef} from 'react'
import axios from 'axios'
//components
import Topbar from './Topbar'
import CookieBanner from './CookieBanner'
import { Container } from '@material-ui/core'
//screens
import {FrontScreen,CategoryScreen,UserScreen,NoConnection,QuestScreen, ResultScreen, IntroScreen, LoginScreen, RegisterScreen} from '../../Screens'


const DataContainer = (props) => {
    //State
    const [screen, setScreen] = useState("FrontScreen")
    const [user, setUser] = useState({prog:{}, avatar: ""})

    const callBackList = useRef([])
    const [toolStatus, setToolStatus] = useState(true)
    //const [logged, setLogged] = useState(false)

    //Check login status (componentDidMount)
    useEffect(() => {
        axios.get('/user')
        .then((res)=>{
            //console.log(res)
            //setToolStatus(true)
            //setScreen("FrontScreen")
            if(res.status === 200){
                setUser(res.data)
            }
        })
        .catch((error)=>{
            //console.log(error)
            setScreen("NoConnection")
        })

        axios.get('/appConfig.json')
        .then((res)=>{
            //console.log('build date:',res.data.buildDate)
        })
        .catch((error)=>{
            //console.log('Cannot find appConfig:',error)
        })
    }, [])

    function isLogged(){
        return ('email' in user)
    }

    function setUserCb(val,cb){
        setUser(val)
        if(cb){
            callBackList.current.push(cb)
        }
    }

    function handleTool(val){
        if(toolStatus !== val){
            setToolStatus(val)
        }
    }
    function handleUser(key,value, cb){
        let keys = key.split('.')

        if(typeof value === 'object'){
            if(keys.length === 1){
                setUser({
                    ...user,
                    [key]:{
                        ...user[key],
                        ...value
                    }
                })
            }
            else if(keys.length === 2){
                setUser({
                    ...user,
                    [keys[0]]:{
                        ...user[keys[0]],
                        [keys[1]]:{
                            ...user[keys[0]][keys[1]],
                            ...value
                        }
                    }
                })
            }
        }
        else{

            if(value === null){
                if( key in user ){
                    let temp_user = user
                    delete temp_user[key]
                    setUser(temp_user)
                }
                return null
            }

            if(keys.length === 1){
                setUser({
                    ...user,
                    [key]:value
                }) 
            }
            else if(keys.length === 2){
                setUser({
                    ...user,
                    [keys[0]]:{
                        ...user[keys[0]],
                        [keys[1]]:value
                    }
                })
            }
        }

        if(cb){
            callBackList.current.push(cb)
        }
        // let keys = key.split('.')
        // if(keys.length === 1){
        //     setUser({
        //         ...user,
        //         [key]:value
        //     })
        // }
        // else if(keys.length === 2){
        //     setUser({
        //         ...user,
        //         [keys[0]]:{
        //             ...user[keys[0]],
        //             [keys[1]]:value
        //         }
        //     })
        // }
    }
    useEffect(() => {
        callBackList.current.forEach((cb)=>{cb()})
        callBackList.current = []
    }, [user])
    //Content Handling
    //let randomKey = Math.floor(Math.random() * 1000) //random key needed to refresh prop changes
    let content
    // if(screen === "FrontScreen"){
    //     content = <FrontScreen setScreen={setScreen}/>
    // }
    // else if(screen === "GameScreen"){
    //     content = <GameScreen setScreen={setScreen}/>
    // }
    // else if(screen === "UserScreen"){
    //     content = <UserScreen setScreen={setScreen}/>
    // }
    //console.log(screen)
    let screenPayload = screen.split('.')
    let randomKey = Math.floor(Math.random() * 1000) //random key needed to refresh prop changes
    switch(screenPayload[0]){
        case "FrontScreen":
            handleTool(false)
            content = <FrontScreen setScreen={setScreen} user={user} isLogged={isLogged}/>
            break;
        case "CategoryScreen":
            handleTool(false)
            content = <CategoryScreen setScreen={setScreen} content="Category" user={user} handleUser={handleUser} setUserCb={setUserCb} isLogged={isLogged}/>
            break;
        case "CategoryScreenExtended":
            handleTool(false)
            content = <CategoryScreen setScreen={setScreen} content="CategoryExtended" user={user} handleUser={handleUser} setUserCb={setUserCb} isLogged={isLogged}/>
            break;
        case "UserScreen":
            handleTool(true)
            content = <UserScreen key={'US-'+randomKey}
                setScreen={setScreen} 
                user={user}
                setUserCb={setUserCb}
                isLogged={isLogged} 
                handleUser={handleUser}
            />
            break;
        case "ResultsScreen":
            handleTool(true)
            //payload 1: category string
            //payload 2: next category index number
            //payload 3: array of category string names
            //payload 4: screen should open dialog when mounted
            content = <ResultScreen category={screenPayload[1]} user={user} handleUser={handleUser} setScreen={setScreen}
                nextCatIndex={screenPayload[2]} cats={screenPayload[3]} openDia={screenPayload[4]}
            />
            break;
        case "NoConnection":
            handleTool(false)
            content = <NoConnection/>
            break;
        case "IntroScreen":
            handleTool(true)
            //payload 1: category string
            //payload 2: next category index number
            //payload 3: array of category string names
            content = <IntroScreen key={"Intro-"+screenPayload[1]} 
                setScreen={setScreen} category={screenPayload[1]} 
                nextCatIndex={screenPayload[2]} cats={screenPayload[3]}/>
            break;
        case "QuestScreen":
            handleTool(true)
            //payload 1: category string
            //payload 2: next category index number
            //payload 3: array of category string names
            content = <QuestScreen key={'qs-'+screenPayload[1]}
                setScreen={setScreen} category={screenPayload[1]} 
                nextCatIndex={screenPayload[2]} cats={screenPayload[3]}
                user={user} handleUser={handleUser}
            />
            break;
        case "LoginScreen":
            handleTool(false)
            //payload 1: Login screen destination
            //payload 2: enable register button (enabled/disabled)
            content = <LoginScreen
                setScreen={setScreen} user={user} setUserCb={setUserCb} handleUser={handleUser} isLogged={isLogged} destination={screenPayload[1]} register={screenPayload[2]}
            />
            break;
        case "RegisterScreen":
            handleTool(false)
            content = <RegisterScreen
                setScreen={setScreen} user={user} handleUser={handleUser} isLogged={isLogged} //<-Näihin vissiin muutoksia?
            />
            break;    
        default:
            handleTool(false)
            content = <p>Unknown screen: {screenPayload[0]}</p>
            break;
    }

    return (
        <div style={{flexGrow:1}}>
            <Topbar setScreen={setScreen} enabled={toolStatus} isLogged={isLogged}/>
            <div style={{marginTop:'85px'}}>
                <Container>
                    {content}
                </Container>
            </div>
            <CookieBanner isLogged={isLogged}/>
            <div className="DEBUG">
                <p>USER:{JSON.stringify(user,null,4)}</p>
            </div>
        </div>
    )
}

export default DataContainer