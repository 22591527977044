import React from 'react'
import { Grid, Typography } from '@material-ui/core'

/*
Props:
title
content
*/
const Sign = (props) => {
    return (
        <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{marginBottom:'-80px'}}
        >
            <Grid item xs={12}>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={0}
                >
                    <Grid item xs={12} sm={9} md={6} style={{backgroundImage:"url('/images/KylttiTop.png')", backgroundSize:'contain', padding:'20px'}}>
                        <Typography style={{color:'white', fontFamily:'grad,sans-serif'}} variant={props.titleVariant ? props.titleVariant : 'h4'} color="textSecondary">{props.title}</Typography>
                        <Typography style={{color:'white', fontFamily:'grad,sans-serif'}} variant="body1">{props.content}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <img alt="sign leg" src="\images\KylttiLeg.png" width={20}/>
                </div>
            </Grid>
        </Grid>
    )
}

export default Sign