import React, {useEffect, useState} from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import axios from 'axios'
import { handlePayload } from '../utils'

//quest components
import QuestController from '../components/quest/QuestController'

/*
Props:
    setScreen
    category
    nextCatIndex
    cats
    user
    handleUser
*/
const QuestScreen = props =>{
    const [questions, setQuestions] = useState([]) //object array
    const [questIndex, setQuestIndex] = useState(1) //current question

    //onMount load category questions
    useEffect(() => {
        //console.log("quest screen mount")
        axios.get(`/questions/categories/${props.category}`)
        .then((res)=>{
            parseQuestions(res.data) //format questions data
        })
        .catch(err=>{
            props.setScreen("NoConnection")
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function parseQuestions(quests){
        let q = quests
        let newQuests = []
        
        while(q.length > 0){
            if('order' in q[0]){
                if(newQuests[q[0].order] === undefined){
                    newQuests[q[0].order] = []
                }
                newQuests[q[0].order].push(q[0])
            }
            else{
                if(newQuests[0] === undefined){
                    newQuests[0] = []
                }
                newQuests[0].push(q[0])
            }
            q.shift()
        }
        //console.log("newQuests: ",newQuests)
        setQuestions(newQuests)
    }
    function nextQuest(){
        let newIndex = questIndex + 1
        if(newIndex !== questions.length){
            setQuestIndex(newIndex)
        }
        else{
            //goto results
            props.setScreen(handlePayload("ResultsScreen",props.category,'null','null','yes'))
        }
    }
    function nextCategory(){
        let cats = props.cats.split(',')
        let next = parseInt(props.nextCatIndex)

        if(cats.length > next){
            props.setScreen(handlePayload('QuestScreen',cats[next],next+1,cats))
        }
    }
    return(
        <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid item>
                {questions.length > 1 ?
                <QuestController key={'quest-'+questIndex} 
                    quest={questions[questIndex]}
                    nextQuest={nextQuest}
                    handleUser={props.handleUser}
                    user={props.user}
                    category={props.category}
                />
                :<CircularProgress/>}
                
            </Grid>
            <div className="DEBUG" style={{marginBottom:'200px'}}>
                <p>{questIndex}</p>
                <Button variant="contained" onClick={()=>{nextQuest()}}>next question</Button>
                <Button variant="contained" onClick={()=>{nextCategory()}}>next category</Button>
            </div>
        </Grid>
    )
}

export default QuestScreen